import useSWR from "swr";
import { User } from "@type/user";

const useUser = () => {
   const { data, error, mutate } = useSWR("users/me", {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      localStorage.removeItem("jwt");
    },
  });
  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
    userMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    user: User;
    userMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useUser;
